import {
	isBlank
}
from 'voUtils/tools.js';

export default {
	data() {
			return {
				name: undefined,
			};
		},
		mounted() {
			// Warte darauf, dass die DOM-Elemente sicher existieren
			this.$nextTick(() => {
				const modal = document.getElementById("index-modal");
				const closeBtn = document.querySelector(".index-close");

				if (!modal || !closeBtn) {
					console.error("Modal oder Close-Button nicht gefunden!");
					return;
				}

				console.log('Modal-Skript geladen');

				// Funktion zum Setzen eines Cookies
				function setCookie(name, value, days) {
					let date = new Date();
					date.setTime(date.getTime() + days * 12 * 60 * 60 * 1000);
					document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
				}

				// Funktion zum Abrufen eines Cookies
				function getCookie(name) {
					let cookies = document.cookie.split("; ");
					for (let cookie of cookies) {
						let [key, value] = cookie.split("=");
						if (key === name) {
							return value;
						}
					}
					return null;
				}

				// Prüfen, ob das Modal bereits angezeigt wurde (Cookie existiert)
				if (!getCookie("modalShown")) {
					modal.style.display = "flex"; // Modal anzeigen
					setCookie("modalShown", "true", 1); // Cookie setzen (1 Tag Gültigkeit)
				}

				// Schließen-Event (X-Button)
				closeBtn.addEventListener("click", function () {
					modal.style.display = "none";
				});

				// Schließen bei Klick außerhalb des Modals
				window.addEventListener("click", function (event) {
					if (event.target === modal) {
						modal.style.display = "none";
					}
				});
			});
		},
};