export default {
	data: {
		selectedImageIndex: undefined,
		lastScrollPosition: 0
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		},

		phoneLink: function () {
			if (VOFFICE.phoneLink) {
				var el = document.getElementById('preloader');

				window.location.href = VOFFICE.phoneLink;
				if (el) {
					el.style.display = "none";
				}
			}
		},
	},

	methods: {
		toogleAdvantages: function () {
			const elements = document.querySelectorAll('.advantage-hidden');
			const button = document.getElementById('showAdvantages');

			let isHidden = Array.from(elements).every(el => el.style.display === 'none' || el.style.display === '');

			if (!isHidden) {
				this.lastScrollPosition = window.scrollY;
			}

			elements.forEach(el => {
				el.style.display = isHidden ? 'block' : 'none';
			});

			button.textContent = isHidden ? 'Weniger Vorteile anzeigen' : 'Alle Vorteile anzeigen';

			if (!isHidden) {
				window.scrollTo({
					top: this.lastScrollPosition - 900,
					behavior: 'smooth'
				});
			}
		},
		showCustomModal: function (id) {
			let wh = window.innerHeight;

			// Get the modal
			const modal = document.getElementById(id);

			if (modal) {
				var dialog = modal.getElementsByClassName("modal-custom-dialog")[0];
				var content = modal.getElementsByClassName("modal-custom-content")[0];
				var body = document.body;

				if (dialog && content) {
					dialog.style.height = wh - 40 + "px";
					content.style.height = wh - 120 + "px";

					modal.style.display = "block";
					body.classList.add('modal-open');

					// When the user clicks anywhere outside of the modal, close it
					window.onclick = function (event) {
						if (event.target == modal) {
							modal.style.display = "none";
							body.classList.remove('modal-open');
						}
					};
				}
				var that = this;
				document.addEventListener('keydown', function (event) {
					that.closeModalOnEscape(id, event);
				});
			}
		},

		closeCustomModal: function (id) {
			const modal = document.getElementById(id);
			var body = document.body;
			modal.style.display = "none";
			body.classList.remove('modal-open');
			var that = this;
			document.removeEventListener('keydown', function (event) {
				that.closeModalOnEscape(id, event);
			});

		},

		closeModalOnEscape: function (id, event) {
			if (event.key === 'Escape') {
				this.closeCustomModal(id);
			}
		},
	},


	filters: {
		formatfeedback: function (value) {
			if (!value) return '';
			if (Number.isInteger(value)) return value.toFixed();
			return (value.toFixed(1).replace('.', ','));
		}
	}



};